import {FaLinkedin, FaInstagram} from "../assets/icons/icons";
import {FaTelegram, FaXTwitter, FaYoutube} from "react-icons/fa6";

export const footerUsefullLinks = [
  {
    Route: "/tos",
    TitleKey: "PageNames.TOS",
  },
  {
    Route: "/privacy",
    TitleKey: "PageNames.Privacy",
  },
  {
    Route: "Links.HelpCenterURL",
    TitleKey: "Resources.HelpCenter.Name",
  },
  {
    Route: "Links.YouTubeChannelURL",
    TitleKey: "Resources.YouTube.Name",
  },
  {
    Route: "/faqs",
    TitleKey: "Resources.FAQs.Name",
  },
];

export const footerCompanyLinks = [
  {
    Route: "/",
    TitleKey: "PageNames.Home",
  },
  {
    Route: "/about-us",
    TitleKey: "PageNames.AboutUs",
  },
  {
    Route: "/pricing",
    TitleKey: "PageNames.Pricing",
  },
  {
    Route: "/affiliate",
    TitleKey: "PageNames.Affiliate",
  },
  {
    Route: "Links.HelpCenterURL",
    TitleKey: "PageNames.Support",
  },
  {
    Route: "https://app.sellthis.com/account/login",
    TitleKey: "PageNames.LogIn",
  },
  {
    Route: "https://app.sellthis.com/account/register",
    TitleKey: "PageNames.SignUp",
  },
];

export const social = [
  {Name: "Instagram", Href: "Links.InstagramURL", Icon: FaInstagram},
  {Name: "YouTube", Href: "Links.YouTubeChannelURL", Icon: FaYoutube},
  {Name: "X", Href: "https://x.com/sellthis_com", Icon: FaXTwitter},
  {Name: "Linkedin", Href: "http://linkedin.com/company/sellthis", Icon: FaLinkedin},
  {Name: "Telegram", Href: "https://t.me/sellthisofficial", Icon: FaTelegram},
];
