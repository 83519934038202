import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import home1 from "../assets/images/home-1.png";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import KeyFeatures from "../component/homeKeyFeatures";
import Pricing from "../component/homePricing";
import HowItWorks from "../component/homeHowItWorks";
import EssentialFeatures from "../component/homeEssentialFeatures";
import HomeFaq from "../component/homeFaq";
import WhyChooseUs from "../component/homeWhyChooseUs";
import Testimonials from "../component/homeTestimonials";
import GetInTouch from "../component/getInTouch";
import HowToStart from "../component/homeHowToStart";
import ModalVideo from "react-modal-video";
import SingleAsinAnalyze from "../component/singleAsinAnalyze";

export default function Index() {
  let [isOpen, setOpen] = useState(false);
  const {t} = useTranslation();

  document.title = t("PageTitles.Home");

  return (
    <>
      <Navbar />
      <section className="relative before:content-[''] before:absolute xl:before:-top-[30%] lg:before:-top-[50%] sm:before:-top-[80%] before:-top-[90%] before:-start-80 before:end-0 before:w-[140rem] before:h-[65rem] ltr:before:-rotate-12 rtl:before:rotate-12 before:bg-indigo-600/5 dark:before:bg-indigo-600/10 before:z-1 items-center overflow-hidden">
        <div className="container relative z-2">
          <div className="grid grid-cols-1 md:mt-44 mt-32 text-center">
            <div className="wow animate__animated animate__fadeIn">
              <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 bg-gradient-to-r from-indigo-600 to-red-600 text-transparent bg-clip-text">{t("MainHeadline")}</h4>
              <p className="text-gray-600 text-lg max-w-2xl mx-auto">{t("MainHeadlineDescription")}</p>

              <div className="py-12 sm:py-10">
                <SingleAsinAnalyze />
              </div>
            </div>
            <div className="relative home-dashboard mt-8 z-3 wow animate__animated animate__fadeIn">
              <img src={home1} alt="" className="mover" />
              <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                <Link
                  to="#"
                  onClick={() => setOpen(true)}
                  data-type="youtube"
                  className="lightbox size-16 lg:size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-indigo-600 dark:bg-slate-900 text-white dark:text-white">
                  <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                </Link>
              </div>
              <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="4If22_7cpmU" onClose={() => setOpen(false)} />
            </div>
          </div>

          <div className="bg-indigo-600 w-8 h-16 z-2 absolute start-2 lg:bottom-28 md:bottom-36 sm:bottom-40 bottom-16"></div>
          <div className="bg-indigo-600/20 w-8 h-16 z-2 absolute start-12 lg:bottom-32 md:bottom-40 sm:bottom-44 bottom-20"></div>

          <div className="bg-indigo-600/20 w-8 h-16 z-2 absolute end-12 xl:bottom-[420px] lg:bottom-[315px] md:bottom-[285px] sm:bottom-80 bottom-32"></div>
          <div className="bg-indigo-600 w-8 h-16 z-2 absolute end-2 xl:bottom-[440px] lg:bottom-[335px] md:bottom-[305px] sm:bottom-[340px] bottom-36"></div>
        </div>
      </section>

      <section className="relative md:py-24 py-16 overflow-hidden">
        <KeyFeatures />

        <HowItWorks />

        <HowToStart />

        <WhyChooseUs />

        <EssentialFeatures />

        <Testimonials />

        <Pricing />

        <HomeFaq />

        <GetInTouch />
      </section>

      <Footer />
    </>
  );
}
