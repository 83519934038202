import React, {useState, useEffect, useRef} from "react";
import {Menu} from "@headlessui/react";
import {ChevronDownIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {marketplaces} from "../data/marketplaces";
import {useTranslation} from "react-i18next";

export default function SingleAsinAnalyze() {
  const {t} = useTranslation();
  const [asin, setAsin] = useState("");
  const [selectedMarketplace, setSelectedMarketplace] = useState(marketplaces[0]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Create a ref for the ASIN input element
  const asinRef = useRef(null);

  const changeLanguage = (marketplace) => {};

  useEffect(() => {
    changeLanguage(selectedMarketplace.Code);
  }, [selectedMarketplace]);

  const handleAnalyze = () => {
    const asinPattern = /^[A-Z0-9]{10}$/;

    if (!asin) {
      setError(t("SingleAsinAnalyze.ASINConnotBeEmpty"));
      asinRef.current.focus(); // Focus the ASIN input if it is empty
      return;
    }

    if (!asinPattern.test(asin)) {
      setError(t("SingleAsinAnalyze.PleaseEnterValidASIN"));
      asinRef.current.focus(); // Focus the ASIN input if it is not valid
      return;
    }

    setError("");
    setLoading(true); // Start loading state

    // Simulate the redirect by setting a timeout (this is optional, purely for visual purposes)
    setTimeout(() => {
      const marketplaceValue = selectedMarketplace.Code;
      const url = `https://app.sellthis.com/dp/${asin}?marketplace=${marketplaceValue}`;
      window.location.href = url;
    }, 500); // Simulated delay before redirecting
  };

  return (
    <div className="rounded-2xl mx-auto mt-4 max-w-2xl bg-white p-7 shadow-lg ring-1 ring-gray-900/5">
      <h3 className="mb-1 md:text-lg text-lg md:leading-normal leading-normal font-semibold">{t("SingleAsinAnalyze.Title")}</h3>
      <p class="text-gray-600 text-lg mx-auto mb-3">{t("SingleAsinAnalyze.Description")}</p>
      <div className="flex flex-col items-center gap-3 md:flex-row md:justify-center md:gap-3">
        <div className="flex w-full gap-3 md:w-auto md:flex-row">
          <input
            ref={asinRef} // Attach the ref to the input
            id="asin"
            name="asin"
            type="text"
            placeholder="ASIN"
            autoComplete="off"
            value={asin}
            onChange={(e) => setAsin(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAnalyze();
              }
            }}
            disabled={loading} // Disable input when loading
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm disabled:opacity-50"
          />
          <div className="relative inline-block text-left w-full md:w-auto">
            <Menu as="div" className="relative">
              <Menu.Button
                className="inline-flex justify-start items-center gap-x-2 rounded-md bg-white px-4 py-2 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-2 hover:ring-inset w-full md:w-auto min-w-[200px] disabled:opacity-50"
                disabled={loading} // Disable dropdown button when loading
              >
                <img src={selectedMarketplace.Flag} alt={t(`Marketplaces.${selectedMarketplace.Code}`)} className="h-5 w-5 rounded-full" />
                {t(`Marketplaces.${selectedMarketplace.Code}`)}
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-auto" aria-hidden="true" />
              </Menu.Button>

              <Menu.Items className="absolute left-0 z-10 mt-1 w-full origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[200px]">
                {marketplaces.map((marketplace) => (
                  <div key={marketplace.Code}>
                    <Menu.Item>
                      {({active}) => (
                        <button
                          onClick={() => setSelectedMarketplace(marketplace)}
                          disabled={loading} // Disable item selection while loading
                          className={`${active ? "bg-indigo-600 text-white" : "text-gray-900"} group flex items-center w-full px-4 py-2 text-sm`}>
                          <img src={marketplace.Flag} alt={t(`Marketplaces.${marketplace.Code}`)} className="mr-3 h-5 w-5 rounded-full" />
                          {t(`Marketplaces.${marketplace.Code}`)}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Menu>
          </div>
        </div>

        <div className="w-full md:w-auto">
          <button
            type="button"
            onClick={handleAnalyze}
            disabled={loading} // Disable button while loading
            className="w-full text-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:w-auto disabled:bg-indigo-300 disabled:cursor-not-allowed align-middle">
            {loading && (
              <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"></path>
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#1C64F2"></path>
              </svg>
            )}

            {t("SingleAsinAnalyze.Button")}
          </button>
        </div>
      </div>

      {error && (
        <div className="mx-auto md:inline-block rounded-md bg-red-50 p-4 mt-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
