import flags_ca from "../assets/images/flags/ca.svg";
import flags_mx from "../assets/images/flags/mx.svg";
import flags_gb from "../assets/images/flags/gb.svg";
import flags_au from "../assets/images/flags/au.svg";

export const marketplaces = [
  {
    Code: "CA",
    Flag: flags_ca,
  },
  {
    Code: "MX",
    Flag: flags_mx,
  },
  {
    Code: "GB",
    Flag: flags_gb,
  },
  {
    Code: "AU",
    Flag: flags_au,
  },
];
