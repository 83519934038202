import React, {useState, useEffect} from "react";
import i18n from "../i18n";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {classNames} from "../utils";

import flags_tr from "../assets/images/flags/tr.png";
import flags_us from "../assets/images/flags/us.svg";

const languages = [
  {
    Name: "English",
    Value: "en",
    Flag: flags_us,
  },
  {
    Name: "Türkçe",
    Value: "tr",
    Flag: flags_tr,
  },
];

export default function LanguageSelector() {
  const getLanguageFromUrl = () => {
    const currentPath = window.location.pathname;
    return currentPath.startsWith("/tr") ? "tr" : "en";
  };

  const [selected, setSelected] = useState(() => languages.find((lang) => lang.Value === getLanguageFromUrl()) || languages[0]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    const currentPath = window.location.pathname;
    let newPath = currentPath;
    if (lng === "tr" && !currentPath.startsWith("/tr")) {
      newPath = `/tr${currentPath}`;
    } else if (lng === "en" && currentPath.startsWith("/tr")) {
      newPath = currentPath.replace("/tr", "");
    }

    // Update the URL without reloading the page
    window.history.pushState({}, "", newPath);
  };

  useEffect(() => {
    changeLanguage(selected.Value);
  }, [selected]);

  useEffect(() => {
    const initialLang = getLanguageFromUrl();
    setSelected(languages.find((lang) => lang.Value === initialLang));
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white/5 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-white/10 hover:ring-2 hover:ring-inset">
          <img src={selected.Flag} alt={selected.Name} className="mr-2 h-5 w-5 flex-shrink-0 rounded-full" />
          {selected.Name}
          <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
        </MenuButton>
      </div>

      <MenuItems className="absolute left-0 z-10 mt-1 w-32 origin-top-left divide-y divide-gray-100 rounded-md bg-dark shadow-lg ring-1 ring-inset ring-white/10 ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="py-1">
          {languages.map((lang, index) => (
            <MenuItem key={index}>
              <Link
                to="#"
                onClick={(e) => {
                  setSelected(lang);
                }}
                className={classNames(lang.Value === selected.Value ? "font-semibold" : "", "group flex items-center px-3 py-2 text-sm text-white data-[focus]:bg-white/5 data-[focus]:text-white")}>
                <img src={lang.Flag} className="mr-3 h-5 w-5 flex-shrink-0 rounded-full" alt={lang.Name} />
                {lang.Name}
              </Link>
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
}
