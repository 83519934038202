export const pricing = {
  frequencies: [
    {Value: "Monthly", Label: "Pricing.Frequencies.Monthly.Label", PriceSuffix: "Pricing.Frequencies.Monthly.PriceSuffix"},
    {Value: "Month6", Label: "Pricing.Frequencies.Month6.Label", PriceSuffix: "Pricing.Frequencies.Month6.PriceSuffix", Discount: "Pricing.Frequencies.Month6.Discount"},
    {Value: "Annually", Label: "Pricing.Frequencies.Annually.Label", PriceSuffix: "Pricing.Frequencies.Annually.PriceSuffix", Discount: "Pricing.Frequencies.Annually.Discount"},
  ],
  tiers: [
    {
      Name: "Lite",
      Id: "lite",
      Price: {Monthly: "$19.9", Month6: "$99.9", Annually: "$199.9"},
      Description: "Pricing.Tiers.Lite.Description",
      Features: ["Pricing.Tiers.Lite.Features.Feature1", "Pricing.Tiers.Lite.Features.Feature2", "Pricing.Tiers.Lite.Features.Feature3", "Pricing.Tiers.Lite.Features.Feature4"],
      MostPopular: false,
      ShowOnHomepage: true,
      OrderForHomepage: 1,
    },
    {
      Name: "Starter",
      Id: "starter",
      Price: {Monthly: "$59.9", Month6: "$299.9", Annually: "$599.9"},
      Description: "Pricing.Tiers.Starter.Description",
      Features: ["Pricing.Tiers.Starter.Features.Feature1", "Pricing.Tiers.Starter.Features.Feature2", "Pricing.Tiers.Starter.Features.Feature3", "Pricing.Tiers.Starter.Features.Feature4"],
      MostPopular: false,
      ShowOnHomepage: true,
      OrderForHomepage: 3,
    },
    {
      Name: "Professional",
      Id: "professional",
      Price: {Monthly: "$79.9", Month6: "$399.9", Annually: "$799.9"},
      Description: "Pricing.Tiers.Professional.Description",
      Features: [
        "Pricing.Tiers.Professional.Features.Feature1",
        "Pricing.Tiers.Professional.Features.Feature2",
        "Pricing.Tiers.Professional.Features.Feature3",
        "Pricing.Tiers.Professional.Features.Feature4",
      ],
      MostPopular: true,
      ShowOnHomepage: true,
      OrderForHomepage: 2,
    },
    {
      Name: "Premium",
      Id: "premium",
      Price: {Monthly: "$199.9", Month6: "$999.9", Annually: "$1,999.9"},
      Description: "Pricing.Tiers.Premium.Description",
      Features: [
        "Pricing.Tiers.Premium.Features.Feature1",
        "Pricing.Tiers.Premium.Features.Feature2",
        "Pricing.Tiers.Premium.Features.Feature3",
        "Pricing.Tiers.Premium.Features.Feature4",
        "Pricing.Tiers.Premium.Features.Feature5",
      ],
      MostPopular: false,
      OrderForHomepage: 4,
    },
  ],
};
