import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes, Navigate, useLocation} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import initializeGA from "./ga4";

import Index from "./pages/index";
import ContactUs from "./pages/contact-us";
import Affiliate from "./pages/affiliate";
import Privacy from "./pages/privacy";
import Tos from "./pages/tos";
import Faqs from "./pages/faqs";
import Pricing from "./pages/pricing";
import Features from "./pages/features";
import FeatureDetail from "./pages/featureDetail";
import AboutUs from "./pages/about-us";

function LanguageSetter({children}) {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    const browserLanguage = navigator.language.startsWith("tr") ? "tr" : "en";
    const isFirstVisit = localStorage.getItem("isLanguageSet") === null;
    const isTurkishPath = path.startsWith("/tr");
    const shouldRedirect = isFirstVisit && browserLanguage === "tr" && !isTurkishPath;
    if (shouldRedirect === true) {
      const newPath = browserLanguage === "tr" ? `/tr${path}` : path.replace("/tr", "");
      localStorage.setItem("isLanguageSet", "true");
      window.location.replace(newPath);
    } else {
      i18n.changeLanguage(isTurkishPath ? "tr" : "en");
    }
  }, [path]);

  // Redirect if the URL contains /en/
  if (path.startsWith("/en")) {
    const newPath = path.replace("/en", "");
    return <Navigate to={newPath} replace />;
  }

  return children;
}

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <LanguageSetter>
          <Routes>
            {/* English routes without lang prefix */}
            <Route path="/" element={<Index />} />
            <Route path="index" element={<Index />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="affiliate" element={<Affiliate />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="tos" element={<Tos />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="features" element={<Features />} />
            <Route path="features/:featureId" element={<FeatureDetail />} />

            {/* Turkish routes with /tr prefix */}
            <Route path="/tr" element={<Index />} />
            <Route path="tr/index" element={<Index />} />
            <Route path="tr/contact-us" element={<ContactUs />} />
            <Route path="tr/affiliate" element={<Affiliate />} />
            <Route path="tr/tos" element={<Tos />} />
            <Route path="tr/faqs" element={<Faqs />} />
            <Route path="tr/pricing" element={<Pricing />} />
            <Route path="tr/about-us" element={<AboutUs />} />
            <Route path="tr/features" element={<Features />} />
            <Route path="tr/features/:featureId" element={<FeatureDetail />} />
          </Routes>
        </LanguageSetter>
      </BrowserRouter>
    </I18nextProvider>
  );
}
